.menuSide {
  right: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #181a1e;
  position: fixed;
  font-size: 1.25rem;
  z-index: 2;
}

.hidden {
  transform: translateX(100%);
}

.visible {
  transform: translateX(0);
}

.menuSide .menuSideItems {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  gap: 25px;
  padding: 30px;
}

.menuSide .menuItem {
  font-size: 3rem;
  list-style: none;
}

.menuSide .closeButton {
  color: #c31432;
  width: 40px;
  height: 40px;
  font-size: 2.5rem;
  border: 0;
  right: 0;
  border-radius: 50%;
  position: absolute;
  margin: 25px;
  background-color: #181a1e;
  box-shadow: 0 0 1.5rem black;
}

.menuStageContainer {
  max-height: 80vh;
  width: 85vw;
  border-radius: 1rem;
  border: 1px solid #000000;
  box-shadow: 0 0 1.5rem #000000;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}
