.playerModal {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.playerModal .insertSection {
  width: 100%;
}

.playerModal .insertSection .radioButtonSelection {
  display: flex;
  align-items: center;
  padding-left: 40%;
}

.playerModal .insertSection .playerRadioButton {
  width: 2rem;
  margin: 15px;
}
