.loginPage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #1a1a1a;
  color: #fff;
}

.loginPage .loginContainer {
  background-color: #262626;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 2rem #000000;
  width: 20rem;
}

.loginPage .loginRow {
  margin-bottom: 15px;
}

.loginPage h2 {
  margin-bottom: 8px;
  font-size: 24px;
}

.loginPage input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  background-color: #333;
  color: #fff;
  border: 1px solid #444;
  border-radius: 4px;
  box-sizing: border-box;
}

.loginPage button {
  background-color: #4caf50;
  color: #fff;
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.loginPage button:hover {
  background-color: #45a049;
}
