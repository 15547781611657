.deleteContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.deleteContainer h1 {
  text-align: center;
}

.confirmationButtons {
  display: flex;
  justify-content: space-evenly;
}

.deleteContainer button {
  font-size: 1.5rem;
  padding: 0.75rem;
  border-radius: 0.5rem;
  width: 7.5rem;
  border: 1px solid #000000;
  background-color: #fff;
  box-shadow: 0 0 2rem #000000;
}

.deleteContainer .yes:hover {
  background-color: #05520e;
  color: #fff;
  cursor: url(../../assets/cursors/mouse-click.png), auto;
}

.deleteContainer .no:hover {
  background-color: #580000;
  color: #fff;
  cursor: url(../../assets/cursors/mouse-click.png), auto;
}
