.imageModal {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.imageModal .insertSection {
  width: 100%;
}

.imageModal .insertSection .imageInput {
  width: 90%;
}
