.stageContainer {
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: top;
  margin: 2.5%;
  border-radius: 1rem;
  height: 90%; /*Passar por props*/
  overflow-y: auto;
}

.stageContainer::-webkit-scrollbar {
  width: 5px;
  cursor: pointer;
}

.stageContainer::-webkit-scrollbar-track {
  background-color: transparent;
}

.stageContainer::-webkit-scrollbar-thumb {
  background-color: #990f02;
  border-radius: 20px;
}

.stagePage {
  background-color: #0d0d0d;
  border: 2px solid #580000;

  background-image: linear-gradient(
      rgba(13, 13, 13, 0.9),
      rgba(13, 13, 13, 0.75)
    ),
    url(../../../assets/backgrounds/background-poker-cards.jpg);

  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
}
