.categoryModal {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  align-self: center;
  width: 100%;
  height: 100%;
}

.insertSection {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}

.insertSection h2 {
  text-align: center;
  font-size: 2rem;
}

.insertSection input {
  margin: 25px;
  height: 2rem;
  border: none;
  border-bottom: 1px solid #000000;
  width: 50%;
  align-self: center;
  font-size: 1.5rem;
  text-align: center;
}
