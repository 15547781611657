.Page {
  display: flex;
}

.SideMenu {
  background-color: #000000;
  width: 20vw;
  height: 100vh;
  /* Arrumar isso */
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
}

.managerMenu {
  padding: 2vh;
  height: 100%;
  width: 80vw;
}

.option {
  height: 5vh;
  border: 1px solid #e6effa;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.option button {
  border: none;
  height: 100%;
  width: 100%;
  font-size: 1.5rem;
  color: #e6effa;
  background-color: transparent;
  border-radius: 0.5rem;
}

.option button:hover {
  background-color: #e6effa;
  color: #000000;
  cursor: url(../assets/cursors/mouse-click.png), auto;
}