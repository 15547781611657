.headerButton {
  position: relative;
  background-color: #202528;
  border-radius: 0 2rem 0 2rem;
  box-shadow: 0 0 2rem #000000;
  height: 3rem;
  width: 15vw;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: url(../../../assets/cursors/mouse-click.png), auto;
}

.headerButton:hover,
.headerButton:focus {
  animation: pulse 1.25s;
  box-shadow: 0 0 0 1.5em transparent;
  background-color: #414b50;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #e6effa;
  }
}

.buttonImage {
  height: 1.75rem;
  width: 1.75rem;
}

.symbolImage {
  width: 1.5rem;
  position: absolute;
  top: -0.75rem;
  margin-right: 12vw;
}

.buttonText {
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .headerButton {
    align-items: center;
    justify-content: center;
  }

  .buttonText {
    display: none;
  }
}
