.modalButton {
  font-size: 1.5rem;
  padding: 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid #000000;
  background-color: #fff;
  box-shadow: 0 0 2rem #000000;
  text-align: center;
}

.modalButton:hover {
  background-color: #181a1e;
  color: #fff;
  cursor: url(../../../assets/cursors/mouse-click.png), auto;
}
