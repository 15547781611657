.animatedContainer {
  height: 6vh;
  width: 6vh;
}

.animatedImage {
  width: 100%;
  height: 100%;
}

@media (max-width: 425px) {
  .animatedContainer {
    height: 5vh;
    width: 5vh;
  }
}
