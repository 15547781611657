.backgroundModal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(0, 0, 0, 0.7);
  z-index: 1000;
}

.modalContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  color: #000000;
  border-radius: 0.5rem;
}

.closeModalButton {
  color: #c31432;
  width: 35px;
  height: 35px;
  font-size: 2rem;
  border: 0;
  right: 0;
  border-radius: 50%;
  position: absolute;
  margin: 15px;
  background-color: #181a1e;
  box-shadow: 0 0 1.5rem black;
}

.closeModalButton:hover {
  background-color: #545454;
  cursor: url(../../../assets/cursors/mouse-click.png), auto;
}

.modalContent {
  height: 100%;
}
